import styled from 'styled-components'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'

const CustomPagination = styled(Pagination)`
  .rc-pagination-item-active {
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
  }
`

export { CustomPagination }
