import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import FormFields from '../common/FormFields'

import { createNotification } from '../../utils/createNotification'
import { susbcribeSchema } from '../../utils/schemas'

class SubscribeForm extends Component {
  state = {
    loading: false,
    clearValues: false,
  }
  handleSubmit = form => {
    const { email, name } = form
    const { clearValues } = this.state
    this.setState({ loading: true }, () => {
      addToMailchimp(email, { FNAME: name }).then(data => {
        const { result } = data
        if (result !== 'error') {
          createNotification('success', {
            title: 'Suscrito correctamente!',
            message: 'Empieza a recibir notifcaciones.',
          })
        } else {
          createNotification('error', {
            title: 'Hubo un error',
            message: 'Esta cuenta ya existe.',
          })
        }
        this.setState({
          loading: false,
          clearValues: result !== 'error' ? !clearValues : clearValues,
        })
      })
    })
  }
  render() {
    const { color = '' } = this.props
    return (
      <FormFields
        submit={this.handleSubmit}
        fields={susbcribeSchema}
        buttonText="button_subscribe"
        clearValues={this.state.clearValues}
        color={color}
      />
    )
  }
}

export default SubscribeForm
