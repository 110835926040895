import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/Layout'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import Section from '../components/common/Section'
import { TitleSection } from '../components/common/Index.styled'
import ArticlePreview from '../components/ArticlePreview'
import FooterContact from '../components/FooterContact'

import image_background from '../images/blog_background.jpg'

import { ContainerBlogs, ContainerNavLinks } from './news-list.styled'
import Pagination from '../components/Pagination'

const Media = props => {
  const { edges: media } = props.data.allContentfulMedia
  const { page, limit, total } = props.pageContext
  return (
    <Layout
      title="Medios | AS Consulting Group"
      description="No te pierdas de nada y enterate de lo que estamos haciendo."
      {...props}
    >
      <SectionHeaderImage image={image_background} position="0" />
      <Section>
        <TitleSection>
          <FormattedMessage id="news.title" />
        </TitleSection>
        <ContainerBlogs>
          {media.map(({ node }) => (
            <ArticlePreview
              key={node.slug}
              article={node}
              horizontal
              pathname={props.location.pathname}
            />
          ))}
        </ContainerBlogs>
        <ContainerNavLinks>
          <div>
            <Pagination
              current={page}
              pageSize={limit}
              total={total}
              navigate={props.navigate}
              location={props.location}
            />
          </div>
        </ContainerNavLinks>
      </Section>
      <FooterContact />
    </Layout>
  )
}

export default Media

export const MediaQuery = graphql`
  query MediaListQuery($skip: Int!, $limit: Int!) {
    allContentfulMedia(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
