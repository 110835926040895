import React from 'react'
import { Row } from 'react-styled-flexboxgrid'

import { Background, ContainerForms } from './FooterContact.styled'
import SubscribeForm from '../subscription/SubscribeForm'
import ContactForm from '../ContactForm'
import { FormattedMessage } from 'react-intl'

const FooterContact = () => (
  <Background>
    <Row center="xs" middle="xs">
      <ContainerForms xs={11} lg={8}>
        <div style={{ color: 'white', maxWidth: '400px' }}>
          <h2>
            <FormattedMessage id="footer-contact.title_form_1" />
          </h2>
          <FormattedMessage id="footer-contact.description_form_1" />
          <ContactForm />
        </div>
        <div style={{ color: 'white', maxWidth: '400px' }}>
          <h2>
            <FormattedMessage id="footer-contact.title_form_2" />
          </h2>
          <FormattedMessage id="footer-contact.description_form_2" />
          <SubscribeForm />
        </div>
      </ContainerForms>
    </Row>
  </Background>
)

export default FooterContact
