import styled from 'styled-components'

const ContainerBlogs = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border-right: 5px solid ${props => props.theme.primary};
  border-radius: 4px;
`
const ContainerNavLinks = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
  & > div {
    display: flex;
    justify-content: space-between;
  }
`
export { ContainerBlogs, ContainerNavLinks }
